<template>
  <b-card>
    <div class="demo-inline-spacing mb-2">
      <h4
        class="card-title"
        style="margin:0"
      >
        인플루언서 정보
      </h4>
      <b-badge
        class="ml-1"
        :variant="influencerData.state === 'ACTIVE' ? 'success':'secondary'"
        style="margin:0"
      >
        {{ influencerData.state }}
      </b-badge>
      <b-badge
        v-if="influencerData.is_blind"
        class="ml-1"
        variant="success"
        style="margin:0"
      >
        가계정
      </b-badge>
    </div>
    <b-row class="mb-1">
      <b-col
        cols="6"
        xl="2"
        lg="2"
        md="4"
      >
        <b-avatar
          :src="influencerData.avatar"
          :text="avatarText(influencerData.name)"
          variant="light-primary"
          size="104px"
          rounded
        />
      </b-col>
      <b-col
        cols="4"
        xl="4"
        lg="5"
        md="6"
      >
        <b-row class="mb-1">
          <b-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            class="d-flex align-items-center"
          >
            <h5 class="mr-1">
              중재
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('turnOnClaimListSidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${claimCount}`"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            class="d-flex align-items-center"
          >
            <h5 class="mr-1">
              채팅
            </h5>
            <b-avatar
              variant="light-primary"
              rounded
              :text="`${chatCount}`"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            class="d-flex align-items-center"
          >
            <h5 class="mr-1">
              경고
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('turn-on-manage-caution-sidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${cautionCount}`"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            class="d-flex align-items-center"
          >
            <h5 class="mr-1">
              평점
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('turnOnScoreSidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${influencerData.score}`"
              />
            </div>
          </b-col>
        </b-row>

      </b-col>
      <b-col
        cols="3"
        xl="2"
        lg="2"
        md="2"
      >
        <b-row class="mb-1">
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <h5 class="mr-1">
              배송지
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('showDeliveryDestinationSidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${deliveryDestinationCount}`"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <span class="mr-1">
              포인트
            </span>
            <span>
              {{ influencerData.point }}
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="3"
        xl="2"
        lg="2"
        md="2"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <b-form-group v-if="ssnData">
              <b-badge
                variant="success"
                class="mb-1"
              >
                실명인증 완료
              </b-badge>
              <div>{{ ssnData.dec_name }}</div>
              <div>{{ ssnData.dec_ssn }}</div>
            </b-form-group>
            <b-form-group v-else>
              <b-badge
                variant="danger"
              >
                실명인증 필요
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="6"
        lg="6"
      >

        <b-form-group>
          <label>Name</label>
          <tagby-copyable-input v-model="influencerData.name" />
        </b-form-group>

        <b-form-group>
          <label>Phone</label>
          <tagby-copyable-input v-model="influencerData.phone" />
        </b-form-group>

        <b-form-group>
          <label>Auth</label>
          <sns-auth-list :sns-auth-data="snsAuthData" />
        </b-form-group>

      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="6"
      >
        <b-row>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Gender</label>
              <v-select
                :value="genderSelected"
                :options="genderOptions"
                @input="$emit('input-gender', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Job</label>
              <b-form-input
                v-model="influencerData.job"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Birth</label>
              <b-form-datepicker
                v-model="influencerData.birth"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Country</label>
              <b-form-input
                v-model="influencerData.country"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group>
              <label>Restricted Date</label>
              <flat-pickr
                :value="restrictedDate"
                class="form-control"
                :disabled="cautionCount === 0"
                @input="$emit('input-restricted-date', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import SnsAuthList from './SnsAuthList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BBadge,
    vSelect,
    flatPickr,
    TagbyCopyableInput,
    SnsAuthList,
  },
  props: {
    influencerData: {
      type: Object,
      required: true,
    },
    genderSelected: {
      type: Object,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    chatCount: {
      type: Number,
      default: 0,
    },
    claimCount: {
      type: Number,
      default: 0,
    },
    cautionCount: {
      type: Number,
      default: 0,
    },
    snsAuthData: {
      type: Array,
      required: true,
    },
    restrictedDate: {
      type: String,
      default: null,
    },
    deliveryDestinationCount: {
      type: Number,
      default: 0,
    },
    ssnData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

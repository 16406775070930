<template>
  <b-card title="마이스토어 정보">
    <b-row>
      <b-col cols="2">
        <b-img
          v-bind="mainProps"
          rounded
          alt="Transparent image"
          class="d-inline-block mr-1 mb-1 border"
          :blank-color="mystoreData.color"
          :src="mystoreData.background_img_url"
        />
      </b-col>
      <b-col cols="10">
        <b-row>
          <b-col
            cols="6"
          >
            <b-form-group>
              <label for="myshop-name">Name</label>
              <b-input-group>
                <b-form-input
                  v-if="mystoreData.idx"
                  id="myshop-name"
                  :value="mystoreData.name"
                  class="custom-hyperlink"
                  readonly
                  @click="$emit('link-to-admin-mystore', mystoreData.idx)"
                />
                <b-form-input
                  v-else
                  readonly
                />
                <b-input-group-append
                  v-clipboard:copy="mystoreData.name"
                  is-text
                  class="cursor-pointer copy-button"
                  @click="$emit('make-copy-toast', mystoreData.name)"
                >
                  <feather-icon
                    icon="CopyIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label for="myshop-url_path">URL Path</label>
              <b-input-group>
                <b-form-input
                  v-if="mystoreLink"
                  id="myshop-url_path"
                  :value="mystoreLink"
                  class="custom-hyperlink"
                  readonly
                  @click="$emit('link-to-mystore')"
                />
                <b-form-input
                  v-else
                  readonly
                />
                <b-input-group-append
                  v-clipboard:copy="mystoreLink"
                  is-text
                  class="cursor-pointer copy-button"
                  @click="$emit('make-copy-toast', mystoreLink)"
                >
                  <feather-icon
                    icon="CopyIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="3">
            담은 상품 개수
          </b-col>
          <b-col cols="3">
            {{ mystoreData.product_count }}
          </b-col>
          <b-col cols="3">
            최근 판매 금액
          </b-col>
          <b-col cols="3">
            {{ mystoreData.order_amount }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="3">
            배송중인 주문 개수
          </b-col>
          <b-col cols="3">
            {{ mystoreData.delivery_count }}
          </b-col>
          <b-col cols="3">
            최근 주문 개수
          </b-col>
          <b-col cols="3">
            {{ mystoreData.order_count }}
          </b-col>
        </b-row>
        <b-row>
          <b-col
            offset="6"
            cols="3"
            class="blockquote-footer"
          >
            최근기준 (30일 내)
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    mainProps: {
      type: Object,
      required: true,
    },
    mystoreData: {
      type: Object,
      required: true,
    },
    mystoreLink: {
      type: String,
      default: null,
    },
  },
  emits: [
    'link-to-admin-mystore',
    'make-copy-toast',
    'link-to-mystore',
  ],
}
</script>

<style>
.custom-hyperlink {
  font-weight: bold;
  color: var(--primary) !important;
}
.custom-hyperlink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>

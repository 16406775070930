<template>
  <tagby-sidebar
    :visible="visible"
    width="40%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      배송지 관리
    </template>

    <template #content>
      <b-table
        :items="items"
        :fields="fields"
        small
      >
        <template #cell(address)="data">
          <span>
            {{ data.item.address }} {{ data.item.address_detail }}
          </span>
        </template>
      </b-table>

      <b-row class="mt-3">
        <b-col>
          <h3>배송지 추가</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="배송지명" label-for="destinationName">
            <b-form-input
              id="destinationName"
              v-model="destinationName"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="수신자이름" label-for="receiverName">
            <b-form-input
              id="receiverName"
              v-model="receiverName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="주소" label-for="deliveryAddress">
            <b-form-input
              id="deliveryAddress"
              v-model="deliveryAddress"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="상세주소" label-for="deliveryAddressDetail">
            <b-form-input
              id="deliveryAddressDetail"
              v-model="deliveryAddressDetail"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="우편번호" label-for="deliveryZipcode">
            <b-form-input
              id="deliveryZipcode"
              v-model="deliveryZipcode"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="전화번호" label-for="deliveryPhone">
            <b-form-input
              id="deliveryPhone"
              v-model="deliveryPhone"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="outline-primary"
            @click="addDeliveryDestination"
            v-text="'추가'"
          />
        </b-col>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { defineComponent, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

import TagbySidebar from '@/components/TagbySidebar.vue'

export default defineComponent({
  props: {
    infIdx: {
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },

  components: {
    TagbySidebar,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BButton,
  },
  setup(props, context) {
    const receiverName = ref('')
    const deliveryPhone = ref('')
    const deliveryAddress = ref('')
    const deliveryAddressDetail = ref('')
    const deliveryZipcode = ref('')
    const destinationName = ref('')

    const toast = useToast()
    const addDeliveryDestination = async () => {
      try {
        const result = await axios.post(
          `${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/${props.infIdx}/add-delivery-destination/`,
          {
            name: receiverName.value,
            phone: deliveryPhone.value,
            address: deliveryAddress.value,
            address_detail: deliveryAddressDetail.value,
            zipcode: deliveryZipcode.value,
            destination_name: destinationName.value,
          },
        )
        toast({
          component: ToastificationContent,
          props: {
            title: '배송지를 성공적으로 추가하였습니다',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        context.emit('addDeliveryDestination', result.data.data)
        receiverName.value = ''
        deliveryPhone.value = ''
        deliveryAddress.value = ''
        deliveryAddressDetail.value = ''
        deliveryZipcode.value = ''
        destinationName.value = ''
      } catch {
        toast({
          component: ToastificationContent,
          props: {
            title: '배송지를 추가에 실패하였습니다',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }
    return {
      receiverName,
      deliveryPhone,
      deliveryAddress,
      deliveryAddressDetail,
      deliveryZipcode,
      destinationName,
      addDeliveryDestination,
    }
  },
})

</script>

<style lang="scss" scoped>

</style>
<template>
  <b-card title="링크 정보">
    <b-row>
      <b-col cols="2">
        <b-img
          v-bind="mainProps"
          rounded
          alt="Transparent image"
          class="d-inline-block mr-1 mb-1 border"
          :src="linkGroupData.thumbnail_img_url"
        />
      </b-col>
      <b-col cols="10">
        <b-row>
          <b-col>
            <b-form-group>
              <label for="myshop-url_path">URL Path</label>
              <b-input-group>
                <b-form-input
                  v-if="tagbyLink"
                  id="myshop-url_path"
                  :value="tagbyLink"
                  class="custom-hyperlink"
                  readonly
                  @click="openLink"
                />
                <b-form-input
                  v-else
                  readonly
                />
                <b-input-group-append
                  v-clipboard:copy="tagbyLink"
                  is-text
                  class="cursor-pointer copy-button"
                  @click="$emit('make-copy-toast', tagbyLink)"
                >
                  <feather-icon
                    icon="CopyIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
import {
  computed,
} from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    mainProps: {
      type: Object,
      required: true,
    },
    linkGroupData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const tagbyLink = computed(() => `${process.env.VUE_APP_TAGBY_LINK_SERVER}/${props.linkGroupData.url_path}`)
    const openLink = () => {
      window.open(tagbyLink.value, '_blank')
    }
    return {
      tagbyLink,
      openLink,
    }
  },
}
</script>

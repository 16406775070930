<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-primary"
          :disabled="isSaving"
          block
          @click="$emit('save-influencer')"
        >
          <b-spinner
            v-if="isSaving"
            small
          />
          {{ saveButtonText }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turn-on-point-sidebar')"
        >
          포인트관리
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turn-on-add-caution-sidebar')"
        >
          경고추가
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turn-on-memo-sidebar')"
        >
          메모
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-danger"
          block
          :disabled="['WITHDRAW', 'BAN_WITHDRAW', 'WAIT', 'BAN_WAIT'].includes(influencerState)"
          @click="$emit('turn-on-delete-influencer-sidebar')"
        >
          회원탈퇴
        </b-button>
      </b-col>

      <b-col
        v-if="['BAN', 'BAN_WITHDRAW'].includes(influencerState)"
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turn-on-release-black-modal')"
        >
          블랙해제
        </b-button>
      </b-col>
      <b-col
        v-else
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          v-b-modal.modal-ban
          variant="outline-secondary"
          block
          @click="$emit('turn-on-black-modal')"
        >
          블랙
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          v-b-modal.modal-revert
          variant="outline-secondary"
          block
          @click="$emit('showDeliveryDestinationSidebar')"
        >
          배송지 추가
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turnOnUpdateScoreSidebar')"
        >
          평점 수정
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    influencerState: {
      type: String,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    saveButtonText: {
      type: String,
      required: true,
    },
  },
  emits: [
    'save-influencer',
    'turn-on-point-sidebar',
    'turn-on-add-caution-sidebar',
    'turn-on-memo-sidebar',
    'turn-on-delete-influencer-sidebar',
    'showDeliveryDestinationSidebar',
  ],
}
</script>

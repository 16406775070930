<template>
  <tagby-sidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      웹 인증 티켓 발급
    </template>
    <template #content>
      <div class="mb-1">
        <b-row>
          <b-col>
            <b-form-group
              label="초대 캠페인 이름"
            >
              <b-form-input
                :value="campaign_title"
                @input="$emit('input-campaign-title', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid || isSaving"
              @click="$emit('upgrad-to-biz')"
            >
              <b-spinner
                v-if="isSaving"
                small
              />
              확인
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    campaign_title: {
      type: String,
      default: '',
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'hidden',
    'upgrad-to-biz',
    'ticket-link-modal',
  ],
  setup() {
  },
}
</script>

<template>
  <tagby-sidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      SNS 추가
    </template>
    <template #content>
      <div class="mb-1">
        <b-row>
          <b-col>
            <b-form-group
              label="서비스"
            >
              <v-select
                :value="snsService"
                :options="serviceOptions"
                @input="$emit('input-sns-service', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="아이디"
            >
              <b-form-input
                :value="snsId"
                @input="$emit('input-sns-id', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid || isSaving"
              @click="$emit('add-new-sns')"
            >
              <b-spinner
                v-if="isSaving"
                small
              />
              확인
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    vSelect,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    snsId: {
      type: String,
      default: '',
    },
    snsService: {
      type: String,
      required: true,
    },
    serviceOptions: {
      type: Array,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'hidden',
    'add-new-sns',
    'input-sns-id',
    'input-sns-service',
  ],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

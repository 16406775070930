<template>
  <div>
    <h3>평가 로그</h3>
    <BTable
      :fields="fields"
      :items="items"
      show-empty
      small
      responsive
    >
      <template #cell(info_idx)="data">
        <BLink :to="{name: 'campaign-compt-active-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(created_at)="data">
        {{ resolveDateTime(data.value) }}
      </template>
    </BTable>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
  },
  props: {
    items: {
      type: Array,
    },
  },
  setup() {
    const fields = [
      'idx', 'info_table', 'info_idx', 'purpose',
      'comment', 'score_data', 'created_at',
    ]

    const {
      resolveDateTime,
    } = useResolveValue()

    return {
      fields,
      resolveDateTime,
    }
  },
})
</script>

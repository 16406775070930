<template>
  <b-modal
    :visible="visible"
    ok-title="확인"
    ok-variant="primary"
    cancel-title="닫기"
    cancel-variant="outline-secondary"
    modal-class="modal-primary"
    centered
    title="경고추가"
    @ok="$emit('ok', {reason, restrictedDate, isBlack, isAppPush})"
    @hidden="$emit('hidden')"
  >
    <b-row>
      <b-col>
        <b-form-group label="사유">
          <b-form-textarea
            v-model="reason"
            rows="8"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <b-form-group label="제한기간">
          <flat-pickr
            :value="restrictedDate"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
            @input="$emit('input-restricted-date', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <!-- <b-row>
      <b-col>
        <b-form-group label="블랙처리">
          <b-form-checkbox
            :checked="isBlack"
            switch
            @input="$emit('input-is-black', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <!-- <b-row>
      <b-col>
        <b-form-group label="알림처리">
          <b-form-checkbox
            v-model="isAppPush"
            switch
          />
        </b-form-group>
      </b-col>
    </b-row> -->
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BFormCheckbox,
    flatPickr,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: true,
    },
    restrictedDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isAppPush: true,
      reason: null,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-card-header class="card-header">
          <span v-if="snsProfile.service === 'NAVER_BLOG'">
            <feather-icon
              icon="FileTextIcon"
              size="24"
            />
            NAVER Blog
          </span>
          <span v-if="snsProfile.service === 'INSTA_BASIC'">
            <feather-icon
              icon="InstagramIcon"
              size="24"
            />
            Instagram
            <b-badge
              class="profile-badge"
              variant="secondary"
              style="cursor:pointer;"
            >
              Basic
            </b-badge>
          </span>
          <span v-if="snsProfile.service === 'INSTA_BIZ'">
            <feather-icon
              icon="InstagramIcon"
              size="24"
            />
            Instagram
            <b-badge
              class="profile-badge"
              variant="warning"
              style="cursor:pointer;"
            >
              Pro
            </b-badge>
          </span>
          <span v-if="snsProfile.service === 'YOUTUBE'">
            <feather-icon
              icon="FileTextIcon"
              size="24"
            />
            Youtube
          </span>
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>
            <b-dropdown-item
              href="#"
              @click="$emit('turn-on-upgrade-to-insta-biz', snsProfile.idx)"
            >
              웹인증 티켓 발급
            </b-dropdown-item>
            <!--            <b-dropdown-item href="#">-->
            <!--              데이터 갱신-->
            <!--            </b-dropdown-item>-->
          </b-dropdown>
          <b-card-text class="text-muted font-small-2">
            <span class="font-weight-bolder">last updated at</span><span> {{ resolveDateTime(snsProfile.updated_at) }}</span>
          </b-card-text>
        </b-card-header>
      </b-col>
    </b-row>

    <hr class="mb-2">

    <div class="d-flex">
      <div
        class="mr-1"
        style="cursor:pointer;"
        @click="openLandingUrl(snsProfile.landing_url)"
      >
        <b-avatar
          v-if="snsProfile.state === 'ACTIVE'"
          size="60"
          variant="light"
          :src="snsProfile.profile_img_url"
        />
        <b-avatar
          v-else
          size="60"
          variant="dark"
        >
          <span>INACTIVE</span>
        </b-avatar>
      </div>
      <div>
        <b-badge
          href="#"
          variant="primary"
        >
          <feather-icon
            icon="LinkIcon"
            class="mr-25"
          />
          <span>{{ snsProfile.idx }}</span>
        </b-badge>
        <div>
          {{ snsProfile.username }}
          <tagby-copy-icon :value="snsProfile.username" />
        </div>
        <div>
          {{ snsProfile.uid }}
          <tagby-copy-icon :value="snsProfile.uid" />
        </div>
        <div
          v-if="snsProfile.naver_inf_idx != null"
          class="text-success"
        >
          네이버 인플루언서
        </div>
      </div>
    </div>

    <hr class="mb-2">
    <b-row>
      <b-col cols="4">
        <h6 class="text-muted font-weight-bolder">
          Follower
        </h6>
        <h5 class="mb-0">
          {{ snsProfile.follower_count }}
        </h5>
      </b-col>
      <b-col cols="4">
        <h6 class="text-muted font-weight-bolder">
          Follow
        </h6>
        <h5 class="mb-0">
          {{ snsProfile.follow_count }}
        </h5>
      </b-col>
      <b-col cols="4">
        <div v-if="snsProfile.service === 'NAVER_BLOG'">
          <h6 class="text-muted font-weight-bolder">
            Reach
          </h6>
          <h5 class="mb-0">
            {{ snsProfile.avg_reach }}
          </h5>
        </div>
        <div v-else>
          <h6 class="text-muted font-weight-bolder">
            Post
          </h6>
          <h5 class="mb-0">
            {{ snsProfile.post_count }}
          </h5>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardHeader, BCardTitle, BCardText,
  BDropdown, BDropdownItem,
  BAvatar, BBadge,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import resolveValue from '@/utils/resolveValue'

function defaultSetting(props) {
  const badgeText = ref(props.snsProfile.service)
  const openLandingUrl = () => window.open(props.snsProfile.landing_url, '_blank')
  return {
    badgeText,
    openLandingUrl,
  }
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BBadge,
    BAvatar,
    BDropdown,
    BDropdownItem,
    TagbyCopyIcon,
  },
  props: {
    snsProfile: {
      type: Object,
      required: true,
    },
  },
  emits: ['turn-on-upgrade-to-insta-biz'],
  setup(props) {
    const {
      resolveDateTime,
    } = resolveValue()
    return {
      resolveDateTime,
      ...defaultSetting(props),
    }
  },
}
</script>

<style scoped>
.my-menu-icon {
  position: absolute;
  top: 1rem;
  right: 0.7rem;
  cursor: pointer;
}
.card-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: none;
  padding: 0px;
  background-color: transparent;
}
.card-title {
  margin-bottom: 0;
}
</style>

<template>
  <tagby-sidebar
    :visible="visible"
    width="40%"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
  >
    <template #title>
      경고 관리
    </template>
    <template #content>
      <BRow>
        <BCol class="text-info mb-1">
          경고 상태 변경은 평점에 영향을 주지 않습니다. 직접 평점 수정 기능을 실행해서 수정해주세요.
        </BCol>
      </BRow>
      <b-row class="mb-1">
        <b-col>
          <b-table
            ref="cautionTable"
            responsive="sm"
            small
            :fields="fields"
            :items="items"
          >

            <template #cell(state)="data">
              <b-form-select
                :value="data.item.is_deleted ? 'DELETED': data.value"
                :options="stateOptions"
                @change="$emit('update-caution-state', {cautionIdx: data.item.idx, cautionState: $event})"
              />
            </template>

            <template #cell(info)="data">
              <div
                v-if="data.item.info_table=='campaign_info'"
              >
                <span>
                  캠페인(
                </span>
                <b-link :to="{name: 'campaign-compt-active-detail', params: {idx: data.item.info_idx}}">
                  {{ data.item.info_idx }}
                </b-link>
                <span>
                  )
                </span>
              </div>

              <div
                v-else-if="data.item.info_table=='product_info'"
              >
                <span>
                  상품(
                </span>
                <b-link :to="{name: 'commerce-product-detail', params: {idx: data.item.info_idx}}">
                  {{ data.item.info_idx }}
                </b-link>
                <span>
                  )
                </span>
              </div>
              <div v-else>
                기타
              </div>
            </template>

            <template #cell(created_at)="data">
              <div style="max-width: 90px;">
                {{ data.value }}
              </div>
            </template>

            <template #cell(details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                button
                size="sm"
                button-variant="outline-primary"
                @change="row.toggleDetails"
              >
                <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card
                no-body
              >
                <span>
                  {{ row.item.reason }}
                </span>
              </b-card>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BLink,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BLink,
    BFormSelect,
    BFormCheckbox,

    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    stateOptions: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'hidden',
    'shown',
    'update-caution-state',
  ],
}
</script>

<template>
  <b-row class="mt-1">
    <b-col
      v-if="snsAuthData.length > 0"
      class="d-flex"
    >
      <b-badge
        v-for="snsAuth in snsAuthDataDistinct"
        :key="snsAuth"
        class="mr-1"
        :variant="resolveSnsAuthBadgeColor(snsAuth)"
        style="margin:0"
      >
        {{ snsAuth }}
      </b-badge>
    </b-col>
    <b-col
      v-else
      class="font-italic text-center text-secondary"
    >
      -- No data --
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
  },
  props: {
    snsAuthData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const snsAuthDataDistinct = Array.from(new Set((props.snsAuthData ?? []).map(e => e.sns_channel)))
    const resolveSnsAuthBadgeColor = snsAuth => {
      if (snsAuth === 'NAVER') {
        return 'success'
      } if (snsAuth === 'GOOGLE') {
        return 'secondary'
      } if (snsAuth === 'KAKAO') {
        return 'warning'
      } if (snsAuth === 'FACEBOOK') {
        return 'primary'
      }
      return 'info'
    }

    return {
      snsAuthDataDistinct,
      resolveSnsAuthBadgeColor,
    }
  },
}
</script>

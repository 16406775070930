<template>
  <tagby-sidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      블랙
    </template>
    <template #content>
      <div class="mb-1">
        <b-row>
          <b-col>
            <b-form-group
              label="Restricted Date"
            >
              <flat-pickr
                :value="restrictedDate"
                class="form-control"
                @input="$emit('input-restricted-date', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="사유"
            >
              <b-form-textarea
                :value="reason"
                rows="8"
                @input="$emit('input-reason', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid || isSaving"
              @click="$emit('ok')"
            >
              <b-spinner
                v-if="isSaving"
                small
              />
              확인
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BSpinner,
    BFormTextarea,
    flatPickr,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    restrictedDate: {
      type: String,
      default: '',
    },
    reason: {
      type: String,
      default: '',
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'ok',
    'hidden',
    'input-restricted-date',
    'input-reason',
  ],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

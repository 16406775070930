<template>
  <tagby-sidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      회원 탈퇴
    </template>
    <template #content>
      <b-row class="mb-1">
        <b-col>
          <b-form-group label="탈퇴 사유">
            <v-select
              :value="reasonType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="reasonTypeOptions"
              class="invoice-filter-select"
              placeholder="검색 항목 선택"
              @input="$emit('input-reason-type', $event)"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="isEtc"
        class="mb-1"
      >
        <b-col>
          <b-form-textarea
            :value="reason"
            rows="8"
            @input="$emit('input-reason', $event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="!isValid"
            @click="$emit('delete')"
          >
            <b-spinner
              v-if="isSaving"
              small
            />
            확인
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="$emit('hidden')"
          >
            취소
          </b-button>
        </b-col>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormTextarea,
    BFormGroup,
    vSelect,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    reasonType: {
      type: String,
      default: '',
    },
    reason: {
      type: String,
      default: '',
    },
    reasonTypeOptions: {
      type: Array,
      required: true,
    },
    isEtc: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'hidden',
    'input-reason-type',
    'input-reason',
    'delete',
  ],
}
</script>

<template>
  <TagbySidebar
    :visible="visible"
    title="스코어 정보"
    width="50%"
    @hidden="$emit('hidden')"
  >
    <template #content>
      <ScoreEvaluateTable :items="evalutates" />
      <ScoreLogTable :items="logs" />
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbySidebar from '@/components/TagbySidebar.vue'
import ScoreEvaluateTable from './ScoreEvaluateTable.vue'
import ScoreLogTable from './ScoreLogTable.vue'

export default defineComponent({
  components: {
    TagbySidebar,
    ScoreEvaluateTable,
    ScoreLogTable,
  },
  props: {
    visible: {
      type: Boolean,
    },
    evalutates: {
      type: Array,
    },
    logs: {
      type: Array,
    },
  },
  setup() {

  },
})
</script>

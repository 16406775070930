<template>
  <TagbySidebarWithButton
    title=""
    :visible="visible"
    :isSaving="isSaving"
    :isValid="true"
    @cancel="$emit('turnOffSidebar')"
    @hidden="$emit('turnOffSidebar')"
    @ok="$emit('updateScore')"
  >
    <TagbyInputRow label="total">
      {{ totalScore }} / 60 (Level {{ totalLevel }})
      <BFormInput
        :value="totalScore"
        number
        max="60"
        min="0"
        type="range"
        disabled
      />
    </TagbyInputRow>

    <TagbyInputRow label="time">
      {{ timeScore }} / 20 (Level {{ timeLevel }})
      <BFormInput
        :value="timeScore"
        number
        max="20"
        min="0"
        type="range"
        @input="$emit('inputTimeScore', $event)"
      />
    </TagbyInputRow>

    <TagbyInputRow label="guide">
      {{ guideScore }} / 20 (Level {{ guideLevel }})
      <BFormInput
        :value="guideScore"
        number
        max="20"
        min="0"
        type="range"
        @input="$emit('inputGuideScore', $event)"
      />
    </TagbyInputRow>

    <TagbyInputRow label="contact">
      {{ contactScore }} / 20 (Level {{ contactLevel }})
      <BFormInput
        :value="contactScore"
        number
        max="20"
        min="0"
        type="range"
        @input="$emit('inputContactScore', $event)"
      />
    </TagbyInputRow>

    <TagbyInputRow label="comment">
      <BFormTextarea
        :value="comment"
        @input="$emit('inputComment', $event)"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BFormTextarea,
  },
  props: {
    visible: {
      type: Boolean,
    },
    timeScore: {
      type: Number,
    },
    guideScore: {
      type: Number,
    },
    contactScore: {
      type: Number,
    },
    totalScore: {
      type: Number,
    },
    timeLevel: {
      type: Number,
    },
    guideLevel: {
      type: Number,
    },
    totalLevel: {
      type: Number,
    },
    contactLevel: {
      type: Number,
    },
    isSaving: {
      type: Boolean,
    },
    comment: {
      type: String,
    },
  },
  setup() {

  },
})
</script>

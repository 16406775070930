<template>
  <div>
    <b-alert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'member-inf-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="7"
      >
        <influencer-info-card
          v-if="influencerData"
          :influencer-data="influencerData"
          :gender-selected="genderSelected"
          :gender-options="genderOptions"
          :chat-count="chatCount"
          :claim-count="claimCount"
          :caution-count="cautionCount"
          :sns-auth-data="snsAuthData"
          :restricted-date="restrictedDate"
          :delivery-destination-count="deliveryDestinationCount"
          :ssn-data="ssnData"
          @showDeliveryDestinationSidebar="showDeliveryDestinationSidebar"
          @turn-on-manage-caution-sidebar="turnOnManageCautionSidebar"
          @input-gender="setGender"
          @input-restricted-date="setRestrictedDate"
          @turnOnScoreSidebar="turnOnScoreSidebar"
          @turnOnClaimListSidebar="turnOnClaimListSidebar"
        />
        <mystore-info-card
          v-if="mystoreData"
          :main-props="mainProps"
          :mystore-data="mystoreData"
          :mystore-link="mystoreLink"
          @link-to-admin-mystore="linkToAdminMystore"
          @make-copy-toast="makeCopyToast"
          @link-to-mystore="linkToMystore"
        />
        <campaign-info-card
          v-if="campaignData"
          :items="campaignItems"
          :columns="campaignColumns"
          :in30days="in30daysCampaign"
          :is-selected="isSelectedCampaign"
          :is-canceled="isCanceledCampaign"
          :is-applied="isAppliedCampaign"
          :is-compt="isComptCampaign"
          :is-expg="isExpgCampaign"
          :is-progressing="isProgressingCampaign"
          :current-page="campaignCurrentPage"
          :per-page="campaignPerPage"
          :total-rows="campaignTotalRows"
          :data-meta="campaignDataMeta"
          @input-in30days="setIn30daysCampaignIn30days"
          @input-is-selected="setIsSelectedCampaign"
          @input-is-progressing="setIsProgressingCampaign"
          @input-is-canceled="setIsCanceledCampaign"
          @input-is-applied="setIsAppliedCampaign"
          @input-is-compt="setIsComptCampaign"
          @input-is-expg="setIsExpgCampaign"
          @input-current-page="setCampaignCurrentPage"
        />
        <LinkInfoCard
          v-if="linkGroupData"
          :main-props="mainProps"
          :linkGroupData="linkGroupData"
          @make-copy-toast="makeCopyToast"
        />

        <b-form-group v-if="snsData">
          <b-form-checkbox
            v-model="isVisibleOnlyActiveSns"
            switch
            inline
          >
            ACTIVE한 SNS만 보기
          </b-form-checkbox>
        </b-form-group>
        <sns-list
          v-if="snsData"
          :sns-data="snsList"
          @turn-on-add-new-sns="turnOnAddNewSnsSidebar"
          @turn-on-upgrade-to-insta-biz="turnOnUpgradeToInstaBizSidebar"
        />
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="5"
      >
        <action-card
          v-if="influencerData"
          :influencer-state="influencerData.state"
          :is-saving="isSaving"
          :save-button-text="saveButtonText"
          @save-influencer="saveInfluencerData"
          @turn-on-point-sidebar="tunrOnPointSidebar"
          @turn-on-add-caution-sidebar="tunrOnAddCautionSidebar"
          @turn-on-memo-sidebar="turnOnMemoSidebar"
          @turn-on-delete-influencer-sidebar="turnOnDeleteInfluencerSidebar"
          @turn-on-black-modal="turnOnBlackInfluencerSidebar"
          @turn-on-release-black-modal="turenOnReleaseBlackInfluencerSidebar"
          @showDeliveryDestinationSidebar="showDeliveryDestinationSidebar"
          @turnOnUpdateScoreSidebar="turnOnUpdateScoreSidebar"
        />
        <tagby-timeline
          v-if="timelineData"
          title="타임라인"
          :timeline-data="timelineData"
        />
      </b-col>
    </b-row>

    <add-new-sns-sidebar
      :visible="visibleAddNewSnsSidebar"
      :sns-id="snsIdToBeAdded"
      :sns-service="serviceOfNewSns"
      :service-options="serviceOptions"
      :is-saving="isSavingAddNewSns"
      :is-valid="isValidAddNewSns"
      @hidden="resetAddNewSnsSidebar"
      @add-new-sns="addNewSns"
      @input-sns-id="setSnsIdToBeAdded"
      @input-sns-service="setServiceOfNewSns"
    />

    <upgrade-to-insta-biz-sidebar
      :visible="visibleUpgradeToInstaBizSidebar"
      :is-saving="isSavingUpgradeToInstaBiz"
      :is-valid="isValidUpgradeToInstaBiz"
      @hidden="resetpgradeToInstaBizForm"
      @upgrad-to-biz="makeTicket"
      @input-campaign-title="setCampaignTitle"
    />

    <manage-point-sidebar
      :visible="visiblePointSidebar"
      :point-state="pointState"
      :point-amount="pointAmount"
      :point-reason="pointReason"
      :point-state-options="pointStateOptions"
      :is-saving="isSavingPoint"
      :is-valid="isValidPoint"
      @hidden="resetPointSidebar"
      @update-point="updatePoint"
      @input-point-state="setPointState"
      @input-point-amount="setPointAmount"
      @input-point-reason="setPointReason"
    />
    <add-caution-sidebar
      :visible="visibleAddCautionSidebar"
      :info-table-selected="infoTableSelected"
      :info-idx="cautionInfoIdx"
      :campaign-reason-selected="campaignReasonSelected"
      :market-reason-selected="marketReasonSelected"
      :written-reason="cautionWrittenReason"
      :info-table-options="infoTableOptions"
      :campaign-reason-options="campaignReasonOptions"
      :market-reason-options="marketReasonOptions"
      :is-saving="isSavingAddCaution"
      :is-valid="isValidAddCaution"
      :is-campaign-step="isCampaignStep"
      :is-market-step="isMarketStep"
      :is-directly-written-reason="isDirectlyWriteReason"
      @hidden="resetAddCaution"
      @add-caution="addCaution"
      @input-info-table="setCautionInfoTable"
      @input-info-idx="setCautionInfoIdx"
      @input-campaign-reason="setCampaignReason"
      @input-market-reason="setMarketReason"
      @input-written-reason="setWrittenReason"
    />
    <tagby-memo
      v-model="memoContent"
      :visible="visibleMemoSidebar"
      :is-saving="isSavingMemo"
      @hidden="resetMemo"
      @save="saveMemo"
    />
    <delete-influencer-sidebar
      :visible="visibleDeleteInfluencerSidebar"
      :reason-type="deleteReasonType"
      :reason="deleteReason"
      :reason-type-options="deleteReasonTypeOptions"
      :is-etc="isEtc"
      :is-saving="isSavingDelete"
      :is-valid="isValidDelete"
      @hidden="resetDeleteSidebar"
      @delete="deleteInfluencer"
      @input-reason-type="setDeleteReasonType"
      @input-reason="setDeleteReason"
    />
    <b-modal
      :visible="visibleBlackInfluencerModal"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="블랙"
      @ok="blackInfluencerInModal"
      @hidden="turnOffBlackInfluencerModal"
    >
      경고가 3회 이상입니다. 블랙처리 하시겠습니까?
    </b-modal>
    <b-modal
      id="ticket-url-modal"
      ref="ticketUrlModal"
      centered
      title="웹 인증 티켓 Url"
      ok-only
      ok-title="Ok"
    >
      <b-card-text>
        {{ linkUrl }}
        <!--        <tagby-copy-icon :value="linkUrl" />-->
      </b-card-text>
    </b-modal>
    <manage-caution-sidebar
      :visible="visibleManageCautionSidebar"
      :items="itemsOfManageCaution"
      :fields="columnsOfManageCaution"
      :stateOptions="cautionStateOptions"
      @shown="fetchCautionItems"
      @hidden="turnOffManageCautionSidebar"
      @update-caution-state="turnOnCautionModal"
    />

    <delivery-destination-sidebar
      :infIdx="infIdx"
      :visible="visibleDeliveryDestinationSidebar"
      :items="deliveryDestinations"
      :fields="columnsOfDeliveryDestination"
      @addDeliveryDestination="addDeliveryDestination"
      @hidden="closeDeliveryDestinationSidebar"
    />

    <black-influencer-sidebar
      :visible="visibleBlackInfluencerSidebar"
      :reason="blackInfluencerReason"
      :is-saving="isSavingBlackInfluencer"
      :is-valid="isValidBlackInfluencer"
      @input-reason="setBlackInfluencerReason"
      @hidden="turnOffBlackInfluencerSidebar"
      @ok="blackInfluencerInSidebar"
    />

    <release-black-influencer-sidebar
      :visible="visibleReleaseBlackInfluencerSidebar"
      :restricted-date="releaseBlackInfluencerRestictedDate"
      :reason="releaseBlackInfluencerReason"
      :is-saving="isSavingReleaseBlackInfluencer"
      :is-valid="isValidReleaseBlackInfluencer"
      @input-restricted-date="setReleaseBlackInfluencerRestrictedDate"
      @input-reason="setReleaseBlackInfluencerReason"
      @hidden="turnOffReleaseBlackInfluencerSidebar"
      @ok="releaseBlackInfluencerInSidebar"
    />

    <add-caution-modal
      :visible="visibleAddCautionModal"
      :is-black="isBlackManagedCaution"
      :restricted-date="restrictedDateManagedCaution"
      @hidden="turnOffAddCautionModal"
      @ok="updateCautionStateToAddition"
      @input-restricted-date="onInputRestrictedDateManagedCaution"
      @input-is-black="onInputIsBlackManagedCautioin"
    />

    <cancel-caution-modal
      :visible="visibleCancelCautionModal"
      :is-release-black="isReleaseBlackManageCaution"
      :restricted-date="restrictedDateManagedCaution"
      @hidden="turnOffCancelCautionModal"
      @ok="updateCautionStateToCancel"
      @input-restricted-date="onInputRestrictedDateManagedCaution"
      @input-is-release-black="onInputIsReleaseBlackManagedCautioin"
    />
    <ScoreSidebar
      :visible="visibleScoreSidebar"
      :evalutates="scoreEvaluates"
      :logs="scoreLogList"
      @hidden="turnOffScoreSidebar"
    />
    <ClaimListSidebar
      :visible="visibleClaimListSidebar"
      :items="claims"
      :fields="claimTableFields"
      @hidden="turnOffClaimListSidebar"
    />
    <UpdateScoreSidebar
      :visible="visibleUpdateScoreSidebar"
      :contactScore="contactScore"
      :guideScore="guideScore"
      :timeScore="timeScore"
      :timeLevel="timeLevel"
      :guideLevel="guideLevel"
      :contactLevel="contactLevel"
      :totalScore="totalScore"
      :totalLevel="totalLevel"
      :comment="commentForUpdateScore"
      :isSaving="isSavingUpdateScore"
      @turnOffSidebar="turnOffUpdateScoreSidebar"
      @updateScore="updateScore"
      @inputTimeScore="inputTimeScore"
      @inputGuideScore="inputGuideScore"
      @inputContactScore="inputContactScore"
      @inputComment="inputCommentForUpdateScore"
    />
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import InfluencerDetailViewModel from './viewModel'
import InfluencerInfoCard from './components/InfluencerInfoCard.vue'
import MystoreInfoCard from './components/MystoreInfoCard.vue'
import SnsList from './components/SnsList.vue'
import ActionCard from './components/ActionCard.vue'
import TagbyTimeline from '@/components/TagbyTimeline.vue'
import CampaignInfoCard from './components/CampaignInfoCard.vue'

import AddNewSnsSidebar from './components/AddNewSnsSidebar.vue'
import UpgradeToInstaBizSidebar from './components/UpgradeToInstaBizSidebar.vue'
import ManagePointSidebar from './components/ManagePointSidebar.vue'
import AddCautionSidebar from './components/AddCautionSidebar.vue'
import TagbyMemo from '@/components/TagbyMemo.vue'
// import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import DeleteInfluencerSidebar from './components/DeleteInfluencerSidebar.vue'
import ManageCautionSidebar from './components/ManageCautionSidebar.vue'
import DeliveryDestinationSidebar from './components/DeliveryDestinationSidebar.vue'
import BlackInfluencerSidebar from './components/BlackInfluencerSidebar.vue'
import ReleaseBlackInfluencerSidebar from './components/ReleaseBlackInfluencerSidebar.vue'
import AddCautionModal from './components/AddCautionModal.vue'
import CancelCautionModal from './components/CancelCautionModal.vue'
import ScoreSidebar from './components/ScoreSidebar.vue'
import ClaimListSidebar from './components/ClaimListSidebar.vue'
import UpdateScoreSidebar from './components/UpdateScoreSidebar.vue'
import LinkInfoCard from './components/LinkInfoCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    BModal,
    BFormCheckbox,
    BFormGroup,
    InfluencerInfoCard,
    MystoreInfoCard,
    SnsList,
    ActionCard,
    TagbyTimeline,
    CampaignInfoCard,

    AddNewSnsSidebar,
    UpgradeToInstaBizSidebar,
    ManagePointSidebar,
    AddCautionSidebar,
    TagbyMemo,
    // TagbyCopyIcon,
    DeleteInfluencerSidebar,
    ManageCautionSidebar,
    DeliveryDestinationSidebar,
    BlackInfluencerSidebar,
    ReleaseBlackInfluencerSidebar,
    AddCautionModal,
    CancelCautionModal,
    ScoreSidebar,
    ClaimListSidebar,
    UpdateScoreSidebar,
    LinkInfoCard,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      linkUrl: '',
    }
  },
  methods: {
    makeTicket() {
      this.upgradeToInstaBiz(data => {
        this.linkUrl = data
        this.$refs.ticketUrlModal.show()
      })
    },
  },
  setup() {
    return {
      ...InfluencerDetailViewModel(),
    }
  },
}
</script>

import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import InfluencerDetailStoreModule from './storeModule'

export default function () {
  // 모든 기능에서 공통적으로 사용
  const APP_STORE_MODULE_NAME = 'app-influencer-detail'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })

  // InfluencerInfoCard
  const influencerData = ref()
  const mystoreData = ref()
  const campaignData = ref()
  const timelineData = ref()
  const snsData = ref()
  const ssnData = ref()
  const snsAuthData = ref()
  const cautionCount = ref()
  const chatCount = ref()
  const claimCount = ref()
  const deliveryDestinations = ref([])
  const deliveryDestinationCount = computed(() => deliveryDestinations.value.length)
  const hasLoadError = ref(false)
  const linkGroupData = ref()

  const infIdx = parseInt(router.currentRoute.params.idx, 10)
  const fetchDetail = () => store.dispatch(`${APP_STORE_MODULE_NAME}/fetchInf`,
    { idx: infIdx })
    .then(response => {
      influencerData.value = response.data.data.influencer_data
      mystoreData.value = response.data.data.mystore_data
      campaignData.value = response.data.data.campaign_data
      snsData.value = response.data.data.sns_data
      snsAuthData.value = response.data.data.sns_auth_data
      chatCount.value = response.data.data.chat_count
      claimCount.value = response.data.data.claim_count
      deliveryDestinations.value = response.data.data.delivery_destinations
      cautionCount.value = response.data.data.caution_count
      timelineData.value = response.data.data.timeline_data
      ssnData.value = response.data.data.ssn_data
      linkGroupData.value = response.data.data.link_group_data
      // influencerData.value.restricted_date = moment(influencerData.value.restricted_date).utc().format('YYYY-MM-DD HH:mm')
    }).catch(() => {
      hasLoadError.value = true
    }).finally(() => {
    })

  const fetchTimelines = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchTimelines`,
      { idx: influencerData.value.idx })
      .then(response => {
        timelineData.value = response.data.data
      }).catch(() => {
        makeToast('danger', '타임라인을 가져오는데 실패했습니다')
      })
  }

  const scoreEvaluates = ref([])
  const fetchScoreEvaluates = () => store.dispatch(`${APP_STORE_MODULE_NAME}/fetchScoreEvaluates`,
    { inf_idx: influencerData.value.idx })
    .then(response => {
      scoreEvaluates.value = response.data.data
    }).catch(() => {
      makeToast('danger', '평가 목록을 가져오는데 실패했습니다')
    })

  const scoreLogList = ref([])
  const fetchScoreLogList = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchScoreLogList`,
      { inf_idx: influencerData.value.idx })
      .then(response => {
        scoreLogList.value = response.data.data
      }).catch(() => {
        makeToast('danger', '평가 로그를 가져오는데 실패했습니다')
      })
  }

  const visibleScoreSidebar = ref(false)
  const turnOnScoreSidebar = () => {
    fetchScoreEvaluates()
    fetchScoreLogList()
    visibleScoreSidebar.value = true
  }
  const turnOffScoreSidebar = () => {
    visibleScoreSidebar.value = false
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, InfluencerDetailStoreModule)
    fetchDetail()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const genderOptions = [
    { label: '남성', value: 'M' },
    { label: '여성', value: 'F' },
  ]
  const genderSelected = computed({
    get() {
      return genderOptions.find(option => option.value === influencerData.value.gender)
    },
    set(item) {
      influencerData.value.gender = item.value
    },
  })
  const restrictedDate = computed({
    get() {
      return moment(influencerData.value.restricted_date).format('YYYY-MM-DD HH:mm:ss')
    },
    set(value) {
      influencerData.value.restricted_date = moment(value).utc().format('YYYY-MM-DD HH:mm:ssZ')
    },
  })
  const setGender = item => { genderSelected.value = item }
  const setRestrictedDate = value => {
    restrictedDate.value = value
  }

  // MystoreInfoCard
  const mainProps = {
    blank: true,
    width: 75,
    height: 75,
    class: 'm1',
  }

  const mystoreLink = computed(() => (mystoreData.value.url_path == null ? null : `${process.env.VUE_APP_MYSTORE_URL}/${mystoreData.value.url_path}`))
  const makeCopyToast = text => {
    if (text !== null && text !== '') {
      makeToast('primary', 'Copy Success', text)
    }
  }
  const linkToAdminMystore = () => { router.push({ name: 'commerce-mystore-view', params: { idx: mystoreData.value.idx } }) }
  const linkToMystore = () => {
    if (mystoreLink.value != null) {
      window.open(mystoreLink.value, '__blank')
    }
  }

  // snsList
  const snsList = ref([])
  const isVisibleOnlyActiveSns = ref(true)
  const filterSnsList = () => {
    if (isVisibleOnlyActiveSns.value) {
      snsList.value = snsData.value.filter(sns => sns.state === 'ACTIVE')
    } else {
      snsList.value = snsData.value
    }
  }
  watch([snsData, isVisibleOnlyActiveSns], () => {
    filterSnsList()
  })

  // AddNewSnsSidebar
  const visibleAddNewSnsSidebar = ref(false)
  const snsIdToBeAdded = ref()
  const serviceOfNewSns = ref('INSTAGRAM')
  const serviceOptions = [
    'INSTAGRAM', 'NAVER_BLOG', 'YOUTUBE',
  ]
  const isSavingAddNewSns = ref(false)
  const isValidAddNewSns = computed(() => Boolean(snsIdToBeAdded.value) && Boolean(serviceOfNewSns.value))
  const turnOnAddNewSnsSidebar = () => { visibleAddNewSnsSidebar.value = true }
  const turnOffAddNewSnsSidebar = () => { visibleAddNewSnsSidebar.value = false }

  const setSnsIdToBeAdded = value => { snsIdToBeAdded.value = value }
  const setServiceOfNewSns = value => { serviceOfNewSns.value = value }

  const resetAddNewSnsSidebar = () => {
    snsIdToBeAdded.value = null
    serviceOfNewSns.value = 'INSTAGRAM'
    turnOffAddNewSnsSidebar()
  }

  const addNewSns = () => {
    isSavingAddNewSns.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/addSns`,
      {
        inf_idx: influencerData.value.idx,
        sns_id: snsIdToBeAdded.value,
        sns_service: serviceOfNewSns.value,
      }).then(() => {
        makeToast('primary', 'SNS추가에 성공했습니다')
        resetAddNewSnsSidebar()
        fetchDetail()
        fetchTimelines()
      }).catch(() => {
        makeToast('danger', 'SNS추가에 실패했습니다')
      }).finally(() => {
        isSavingAddNewSns.value = false
      })
  }

  // UpgradeToInstaBizSidebar
  const visibleUpgradeToInstaBizSidebar = ref(false)
  const snsIdxOfUpgradeToInstaBiz = ref()
  const campaignTitleOfUpgradeToInstaBiz = ref()
  const isSavingUpgradeToInstaBiz = ref(false)
  const isValidUpgradeToInstaBiz = computed(() => Boolean(campaignTitleOfUpgradeToInstaBiz.value))

  const setCampaignTitle = campaignTitle => { campaignTitleOfUpgradeToInstaBiz.value = campaignTitle }

  const turnOnUpgradeToInstaBizSidebar = clickedSnsIdx => {
    snsIdxOfUpgradeToInstaBiz.value = clickedSnsIdx
    visibleUpgradeToInstaBizSidebar.value = true
  }
  const turnOffUpgradeToInstaBizSidebar = () => { visibleUpgradeToInstaBizSidebar.value = false }
  const resetpgradeToInstaBizForm = () => {
    campaignTitleOfUpgradeToInstaBiz.value = null
    turnOffUpgradeToInstaBizSidebar()
  }

  const upgradeToInstaBiz = callback => {
    isSavingUpgradeToInstaBiz.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/makeSnsAuthTicket`,
      {
        sns_idx: snsIdxOfUpgradeToInstaBiz.value,
        campaign_title: campaignTitleOfUpgradeToInstaBiz.value,
      })
      .then(response => {
        callback(response.data.data.link_url)
        makeToast('primary', '발급성공.')
        resetpgradeToInstaBizForm()
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '발급실패')
      })
      .finally(() => {
        isSavingUpgradeToInstaBiz.value = false
      })
  }

  // ActionCard
  const isSaving = ref(false)
  const saveButtonText = computed(() => (isSaving.value ? '저장중' : '저장'))
  const saveInfluencerData = () => {
    isSaving.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/updateInf`,
      {
        idx: influencerData.value.idx,
        name: influencerData.value.name,
        phone: influencerData.value.phone,
        email: influencerData.value.email,
        gender: influencerData.value.gender,
        job: influencerData.value.job,
        birth: influencerData.value.birth,
        country: influencerData.value.country,
        address: influencerData.value.address,
        address_detail: influencerData.value.address_detail,
        restricted_date: influencerData.value.restricted_date != null ? moment(influencerData.value.restricted_date).set({ hour: 23, minute: 59, second: 59 }).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      })
      .then(() => {
        makeToast('primary', '인플루언서 정보수정에 성공했습니다')
      })
      .catch(() => {
        makeToast('danger', '인플루언서 정보수정에 실패했습니다')
      })
      .finally(() => {
        isSaving.value = false
        fetchDetail()
      })
  }

  // ManagePointSidebar
  const visiblePointSidebar = ref(false)
  const pointState = ref('ADDITION')
  const pointAmount = ref()
  const pointReason = ref()
  const pointStateOptions = [
    { text: '적립', value: 'ADDITION' },
    { text: '회수', value: 'CANCEL' },
  ]
  const isSavingPoint = ref(false)
  const isValidPoint = computed(() => Boolean(pointState.value) && Boolean(pointAmount.value) && Boolean(pointReason.value))

  const setPointState = value => { pointState.value = value }
  const setPointAmount = value => { pointAmount.value = value }
  const setPointReason = value => { pointReason.value = value }

  const tunrOnPointSidebar = () => { visiblePointSidebar.value = true }
  const tunrOffPointSidebar = () => { visiblePointSidebar.value = false }
  const resetPointSidebar = () => {
    pointState.value = 'ADDITION'
    pointAmount.value = null
    pointReason.value = null
    tunrOffPointSidebar()
  }
  const updatePoint = () => {
    isSavingPoint.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/pointInf`,
      {
        idx: influencerData.value.idx,
        state: pointState.value,
        point: pointAmount.value,
        reason: pointReason.value,
      })
      .then(() => {
        makeToast('primary', '포인트 추가에 성공했습니다')
        resetPointSidebar()
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '포인트 추가에 실패했습니다')
      })
      .finally(() => {
        isSavingPoint.value = false
      })
  }

  // Memo
  const visibleMemoSidebar = ref(false)
  const memoContent = ref()
  const isValidMemo = computed(() => Boolean(memoContent.value))
  const isSavingMemo = ref(false)
  const turnOnMemoSidebar = () => { visibleMemoSidebar.value = true }
  const turnOffMemoSidebar = () => { visibleMemoSidebar.value = false }
  const resetMemo = () => {
    memoContent.value = null
    turnOffMemoSidebar()
  }
  const saveMemo = () => {
    isSavingMemo.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/memoInf`,
      {
        idx: influencerData.value.idx,
        content: memoContent.value,
      }).then(() => {
        makeToast('primary', '메모 생성에 성공했습니다')
        resetMemo()
        fetchTimelines()
      }).catch(() => {
        makeToast('danger', '메모 생성에 실패했습니다')
      }).finally(() => {
        isSavingMemo.value = false
      })
  }

  // Revert
  const revertInfluencer = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/revertInf`,
      { idx: influencerData.value.idx })
      .then(() => {
        makeToast('primary', '재가입에 성공했습니다')
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '재가입에 실패했습니다')
      })
  }

  // Delete
  const visibleDeleteInfluencerSidebar = ref(false)
  const turnOnDeleteInfluencerSidebar = () => { visibleDeleteInfluencerSidebar.value = true }
  const turnOffDeleteInfluencerSidebar = () => { visibleDeleteInfluencerSidebar.value = false }
  const deleteReasonType = ref()
  const deleteReason = ref()
  const deleteReasonTypeOptions = [
    '쓰지 않는 앱이에요',
    '선정이 잘 안 돼요',
    '포인트 출금이 안 돼요',
    '원하는 캠페인이 없어요',
    '판매하고 싶은 제품이 없어요',
    '제품 판매가 잘 되지 않아요',
    '오류가 생겨서 쓰기 어려워요',
    '앱 사용법을 모르겠어요',
    '기타',
  ]
  const setDeleteReasonType = value => { deleteReasonType.value = value }
  const setDeleteReason = value => { deleteReason.value = value }
  const isEtc = computed(() => deleteReasonType.value === '기타')
  const isValidDelete = computed(() => {
    if (!isEtc.value) {
      return Boolean(deleteReasonType.value)
    }
    return Boolean(deleteReason.value)
  })
  const isSavingDelete = ref(false)

  const resetDeleteSidebar = () => {
    deleteReasonType.value = null
    deleteReason.value = null
    turnOffDeleteInfluencerSidebar()
  }

  const deleteInfluencer = () => {
    isSavingDelete.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/deleteInf`,
      {
        idx: influencerData.value.idx,
        reason_type: deleteReasonType.value,
        reason: deleteReason.value,
      })
      .then(() => {
        makeToast('primary', '회원탈퇴에 성공했습니다')
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '회원탈퇴에 실패했습니다')
      })
      .finally(() => {
        isSavingDelete.value = false
        resetDeleteSidebar()
      })
  }

  // CampaignInfoCard
  const campaignColumns = [
    'idx', 'title',
    { key: 'brand_name', label: 'brand' },
    'type', 'step', 'state',
    { key: 'apply_state', label: 'APPLY_STATE' },
    { key: 'apply_created_at', label: 'APPLY_DATE' },
  ]
  const campaignItems = ref([])
  const in30daysCampaign = ref(false)
  const isSelectedCampaign = ref(false)
  const isCanceledCampaign = ref(false)
  const isComptCampaign = ref(false)
  const isAppliedCampaign = ref(false)
  const isExpgCampaign = ref(false)
  const isProgressingCampaign = ref(false)
  const campaignCurrentPage = ref(1)
  const campaignPerPage = ref(5)

  const campaignTotalRows = computed(() => campaignItems.value.length)
  const campaignDataMeta = computed(() => ({
    from: (campaignCurrentPage.value - 1) * campaignPerPage.value + 1,
    to: campaignCurrentPage.value * campaignPerPage.value,
    of: campaignTotalRows.value,
  }))

  const campaignFilter = () => {
    campaignItems.value = campaignData.value
    if (in30daysCampaign.value) {
      campaignItems.value = campaignItems.value.filter(e => moment(e.created_at) >= moment().subtract(30, 'days'))
    }
    const applyStateCondition = []
    if (isSelectedCampaign.value) {
      applyStateCondition.push('SELECTED')
    }
    if (isCanceledCampaign.value) {
      applyStateCondition.push('CANCELED')
      applyStateCondition.push('QUIT')
    }
    if (isAppliedCampaign.value) {
      applyStateCondition.push('APPLY')
      applyStateCondition.push('TEMP')
    }
    if (applyStateCondition.length > 0) {
      campaignItems.value = campaignItems.value.filter(e => applyStateCondition.includes(e.apply?.state))
    }
    if (isComptCampaign.value && !isExpgCampaign.value) {
      campaignItems.value = campaignItems.value.filter(e => e.type === 'COMPT')
    }
    if (!isComptCampaign.value && isExpgCampaign.value) {
      campaignItems.value = campaignItems.value.filter(e => e.type === 'EXPG')
    }
    if (isProgressingCampaign.value) {
      campaignItems.value = campaignItems.value
        .filter(e => e.apply?.state === 'SELECTED')
        .filter(e => !e.is_completed)
        .filter(e => !['RECRUIT', 'PENDING', 'PROGRESSED'].includes(e.step))
    }
  }

  const setIn30daysCampaignIn30days = value => { in30daysCampaign.value = value }
  const setIsSelectedCampaign = value => { isSelectedCampaign.value = value }
  const setIsAppliedCampaign = value => { isAppliedCampaign.value = value }
  const setIsCanceledCampaign = value => { isCanceledCampaign.value = value }
  const setIsComptCampaign = value => { isComptCampaign.value = value }
  const setIsExpgCampaign = value => { isExpgCampaign.value = value }
  const setIsProgressingCampaign = value => { isProgressingCampaign.value = value }
  const setCampaignCurrentPage = value => { campaignCurrentPage.value = value }

  watch([campaignData, in30daysCampaign, isCanceledCampaign, isSelectedCampaign, isComptCampaign, isExpgCampaign, isProgressingCampaign, isAppliedCampaign], () => {
    campaignFilter()
  })

  // ManageCautionSidebar
  const visibleManageCautionSidebar = ref(false)
  const turnOnManageCautionSidebar = () => { visibleManageCautionSidebar.value = true }
  const turnOffManageCautionSidebar = () => { visibleManageCautionSidebar.value = false }
  const itemsOfManageCaution = ref([])
  const columnsOfManageCaution = [
    'idx',
    'info',
    { key: 'details', label: 'reason' },
    'state',
    { key: 'created_at', label: 'created_at', formatter: e => moment(e).format('YYYY-MM-DD HH:mm:ss') },
  ]
  const cautionStateOptions = [
    { text: '추가', value: 'ADDITION' },
    { text: '취소', value: 'CANCELED' },
    // { text: '삭제', value: 'DELETED' },
  ]
  const fetchCautionItems = () => {
    itemsOfManageCaution.value = []
    store.dispatch(`${APP_STORE_MODULE_NAME}/getInfCautions`,
      {
        inf_idx: influencerData.value.idx,
      })
      .then(response => {
        itemsOfManageCaution.value = response.data.data
      })
      .catch(() => {
        makeToast('danger', '경고목록을 조회하는데 실패했습니다')
      })
  }
  const idxManagedCaution = ref()
  const restrictedDateManagedCaution = ref()
  const isBlackManagedCaution = ref(false)
  const isReleaseBlackManageCaution = ref(false)
  const onInputRestrictedDateManagedCaution = value => {
    restrictedDateManagedCaution.value = value
  }
  const onInputIsBlackManagedCautioin = value => {
    isBlackManagedCaution.value = value
  }
  const onInputIsReleaseBlackManagedCautioin = value => {
    isReleaseBlackManageCaution.value = value
  }

  const visibleAddCautionModal = ref(false)
  const turnOnAddCautionModal = () => { visibleAddCautionModal.value = true }
  const turnOffAddCautionModal = () => {
    visibleAddCautionModal.value = false
    fetchCautionItems()
  }

  const visibleCancelCautionModal = ref(false)
  const turnOnCancelCautionModal = () => { visibleCancelCautionModal.value = true }
  const turnOffCancelCautionModal = () => {
    visibleCancelCautionModal.value = false
    fetchCautionItems()
  }

  const visibleDeleteCautionModal = ref(false)
  const turnOnDeleteCautionModal = () => { visibleDeleteCautionModal.value = true }
  const turnOffDeleteCautionModal = () => { visibleDeleteCautionModal.value = false }

  const turnOnCautionModal = params => {
    idxManagedCaution.value = params.cautionIdx
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchCautionCount`, { inf_idx: influencerData.value.idx })
      .then(response => {
        if (params.cautionState === 'ADDITION') {
          isBlackManagedCaution.value = response.data.data.caution_count >= 2
          if (response.data.data.caution_count === 1) {
            restrictedDateManagedCaution.value = moment(influencerData.value.restricted_date).add(30, 'days').format('YYYY-MM-DD HH:mm:ss')
          } else if (response.data.data.caution_count === 0) {
            restrictedDateManagedCaution.value = moment().add(15, 'days').format('YYYY-MM-DD HH:mm:ss')
          } else {
            restrictedDateManagedCaution.value = moment(influencerData.value.restricted_date).format('YYYY-MM-DD HH:mm:ss')
          }
          turnOnAddCautionModal()
        } else if (params.cautionState === 'CANCELED') {
          if (response.data.data.caution_count === 2) {
            restrictedDateManagedCaution.value = moment(influencerData.value.restricted_date).subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss')
          } else if (response.data.data.caution_count === 1) {
            restrictedDateManagedCaution.value = moment(influencerData.value.restricted_date).subtract(15, 'days').format('YYYY-MM-DD HH:mm:ss')
          } else {
            restrictedDateManagedCaution.value = moment(influencerData.value.restricted_date).format('YYYY-MM-DD HH:mm:ss')
          }

          if (response.data.data.caution_count === 3) {
            isReleaseBlackManageCaution.value = ['BAN', 'BAN_WITHDRAW'].includes(influencerData.value.state)
          }
          turnOnCancelCautionModal()
        }
      })
  }

  const updateCautionStateToAddition = params => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/updateCautionStateToAddition`,
      {
        caution_idx: idxManagedCaution.value,
        reason: params.reason,
        restricted_date: moment(restrictedDateManagedCaution.value).utc().format('YYYY-MM-DD HH:mm:ss'),
        is_app_push: params.isAppPush,
        is_black: params.isBlack,
      })
      .then(() => {
        makeToast('primary', '경고목록을 업데이트하는데 성공했습니다')
        fetchDetail()
        fetchCautionItems()
      })
      .catch(() => {
        makeToast('danger', '경고목록을 업데이트하는데 실패했습니다')
      })
      .finally(() => {
      })
  }

  const updateCautionStateToCancel = params => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/updateCautionStateToCancel`,
      {
        caution_idx: idxManagedCaution.value,
        reason: params.reason,
        restricted_date: moment(restrictedDateManagedCaution.value).utc().format('YYYY-MM-DD HH:mm:ss'),
        is_app_push: params.isAppPush,
        is_release_black: params.isReleaseBlack,
      })
      .then(() => {
        makeToast('primary', '경고목록을 업데이트하는데 성공했습니다')
        fetchDetail()
        fetchCautionItems()
      })
      .catch(() => {
        makeToast('danger', '경고목록을 업데이트하는데 실패했습니다')
      })
      .finally(() => {
      })
  }

  const updateCautionState = params => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/infUpdateCautionState`,
      {
        caution_idx: params.cautionIdx,
        state: params.cautionState,
      })
      .then(() => {
        makeToast('primary', '경고목록을 업데이트하는데 성공했습니다')
        fetchDetail()
        fetchCautionItems()
      })
      .catch(() => {
        makeToast('danger', '경고목록을 업데이트하는데 실패했습니다')
      })
      .finally(() => {
      })
  }

  // 배송지 sidebar
  const visibleDeliveryDestinationSidebar = ref(false)
  const columnsOfDeliveryDestination = [
    { key: 'destination_name', label: '배송지명' },
    { key: 'address', label: '주소' },
    { key: 'zipcode', label: '우편번호' },
    { key: 'name', label: '수신자이름' },
  ]
  const showDeliveryDestinationSidebar = () => {
    visibleDeliveryDestinationSidebar.value = true
  }
  const closeDeliveryDestinationSidebar = () => {
    visibleDeliveryDestinationSidebar.value = false
  }
  const addDeliveryDestination = destinationData => {
    deliveryDestinations.value.push(destinationData)
  }

  // BlackInfluencerSidebar
  const visibleBlackInfluencerSidebar = ref(false)
  const turnOnBlackInfluencerSidebar = () => { visibleBlackInfluencerSidebar.value = true }
  const turnOffBlackInfluencerSidebar = () => { visibleBlackInfluencerSidebar.value = false }
  const blackInfluencerReason = ref()
  const setBlackInfluencerReason = value => { blackInfluencerReason.value = value }
  const isSavingBlackInfluencer = ref(false)
  const isValidBlackInfluencer = computed(() => Boolean(blackInfluencerReason.value))
  const blackInfluencer = params => {
    isSavingBlackInfluencer.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/banInf`,
      {
        idx: influencerData.value.idx,
        reason: params.reason,
      })
      .then(() => {
        makeToast('primary', '인플루언서 블랙에 성공했습니다')
        turnOffBlackInfluencerSidebar()
      })
      .catch(() => {
        makeToast('danger', '인플루언서 블랙에 실패했습니다')
      })
      .finally(() => {
        isSavingBlackInfluencer.value = false
        fetchDetail()
      })
  }
  const blackInfluencerInSidebar = () => {
    blackInfluencer({
      reason: blackInfluencerReason.value,
    })
  }

  // ReleaseBlackInfluencerSidebar
  const visibleReleaseBlackInfluencerSidebar = ref(false)
  const turenOnReleaseBlackInfluencerSidebar = () => { visibleReleaseBlackInfluencerSidebar.value = true }
  const turnOffReleaseBlackInfluencerSidebar = () => { visibleReleaseBlackInfluencerSidebar.value = false }
  const releaseBlackInfluencerRestictedDate = ref()
  const releaseBlackInfluencerReason = ref()
  const setReleaseBlackInfluencerRestrictedDate = value => { releaseBlackInfluencerRestictedDate.value = value }
  const setReleaseBlackInfluencerReason = value => { releaseBlackInfluencerReason.value = value }
  const isSavingReleaseBlackInfluencer = ref(false)
  const isValidReleaseBlackInfluencer = computed(() => Boolean(releaseBlackInfluencerReason.value) && Boolean(releaseBlackInfluencerRestictedDate.value))
  const releaseBlackInfluencer = params => {
    isSavingReleaseBlackInfluencer.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/releaseBanInf`,
      {
        idx: params.idx,
        restricted_date: params.restricted_date,
        reason: params.reason,
      })
      .then(() => {
        makeToast('primary', '인플루언서 블랙해제에 성공했습니다')
        turnOffReleaseBlackInfluencerSidebar()
      })
      .catch(() => {
        makeToast('danger', '인플루언서 블랙해제에 실패했습니다')
      })
      .finally(() => {
        isSavingReleaseBlackInfluencer.value = false
        fetchDetail()
      })
  }
  const releaseBlackInfluencerInSidebar = () => {
    releaseBlackInfluencer({
      idx: influencerData.value.idx,
      restricted_date: moment(releaseBlackInfluencerRestictedDate.value).set({ hour: 23, minute: 59, second: 59 }).utc().format('YYYY-MM-DD HH:mm:ss'),
      reason: releaseBlackInfluencerReason.value,
    })
  }

  // BlackInfluencerModal
  const visibleBlackInfluencerModal = ref(false)
  const turnOnBlackInfluencerModal = () => { visibleBlackInfluencerModal.value = true }
  const turnOffBlackInfluencerModal = () => { visibleBlackInfluencerModal.value = false }
  const blackInfluencerInModal = () => { blackInfluencer({ reason: `${cautionCount.value}회의 경고로 인한 블랙` }) }

  // AddCautionSidebar
  const visibleAddCautionSidebar = ref(false)
  const cautionInfoTable = ref()
  const cautionInfoIdx = ref()
  const cautionSelectedReason = ref()
  const cautionWrittenReason = ref()

  const tunrOnAddCautionSidebar = () => { visibleAddCautionSidebar.value = true }
  const tunrOffAddCautionSidebar = () => { visibleAddCautionSidebar.value = false }
  const infoTableOptions = [
    { label: '캠페인', value: 'campaign_info' },
    { label: '마켓', value: 'product_info' },
    { label: '기타', value: 'etc' },
  ]
  const campaignReasonOptions = [
    '미션기간 내에 포스팅을 등록하지 않은 경우',
    '캠페인 가이드를 충분히 이해하지 않고 캠페인을 신청&진행한 경우',
    '포스팅 유지기간 1개월 미준수 및 계정 비공개 전환 등 인증이 불가능 한 경우',
    '가이드에 맞지 않은 포스팅으로 재업로드 및 수정 요청을 수행하지 않은 경우',
    '광고주의 연락을 고의로 받지 않거나, 캠페인 진행에 영향을 미칠 정도로 늦게 연락을 주는 경우',
    '제품 협찬 캠페인의 경우, 제품을 받고 어떠한 연락도 되지 않는 경우',
    '현금 지급 캠페인의 경우 캠페인 기간을 지키지 못할 시에 제공 금액 삭감될 수 있음.',
    '방문 캠페인의 경우, 증빙서류 없는 No-show가 발생할 경우',
    '캠페인 선정 후 일방적으로 진행 취소하는 경우',
    '캠페인 진행으로 제공받은 제공 내역을 타인에게 양도 및 판매, 교환하는 경우',
    { label: '기타 (직접입력)', value: 'etc' },
  ]
  const marketReasonOptions = [
    '온라인 가격 노출 적발',
    '외부 스토어에 상품 판매 적발',
    { label: '기타 (직접입력)', value: 'etc' },
  ]
  const infoTableSelected = computed({
    get() {
      return infoTableOptions.find(option => option.value === cautionInfoTable.value)
    },
    set(item) {
      cautionInfoTable.value = item.value
    },
  })
  const campaignReasonSelected = computed({
    get() {
      return campaignReasonOptions.find(option => (option?.value ?? option) === cautionSelectedReason.value)
    },
    set(item) {
      cautionSelectedReason.value = item?.value ?? item
    },
  })
  const marketReasonSelected = computed({
    get() {
      return marketReasonOptions.find(option => (option?.value ?? option) === cautionSelectedReason.value)
    },
    set(item) {
      cautionSelectedReason.value = item?.value ?? item
    },
  })
  const isValidAddCaution = computed(() => {
    if ((cautionInfoTable.value === 'campaign_info' || cautionInfoTable.value === 'product_info') && Boolean(cautionInfoIdx.value) && Boolean(cautionSelectedReason.value) && cautionSelectedReason.value !== 'etc') {
      return true
    }
    if ((cautionInfoTable.value === 'campaign_info' || cautionInfoTable.value === 'product_info') && Boolean(cautionInfoIdx.value) && cautionSelectedReason.value === 'etc' && Boolean(cautionWrittenReason.value)) {
      return true
    }
    if (cautionInfoTable.value === 'etc' && Boolean(cautionWrittenReason.value)) {
      return true
    }
    return false
  })
  const isSavingAddCaution = ref(false)
  watch(cautionInfoTable, () => {
    cautionInfoIdx.value = null
    cautionSelectedReason.value = null
    cautionWrittenReason.value = null
  })
  watch(cautionSelectedReason, () => {
    cautionWrittenReason.value = null
  })

  const setCautionInfoTable = value => { infoTableSelected.value = value }
  const setCautionInfoIdx = value => { cautionInfoIdx.value = value }
  const setCampaignReason = value => { campaignReasonSelected.value = value }
  const setMarketReason = value => { marketReasonSelected.value = value }
  const setWrittenReason = value => { cautionWrittenReason.value = value }

  const isCampaignStep = computed(() => cautionInfoTable.value === 'campaign_info')
  const isMarketStep = computed(() => cautionInfoTable.value === 'product_info')
  const isDirectlyWriteReason = computed(() => (cautionInfoTable.value === 'etc') || (cautionSelectedReason.value === 'etc'))
  const resetAddCaution = () => {
    cautionInfoTable.value = null
    cautionInfoIdx.value = null
    cautionSelectedReason.value = null
    cautionWrittenReason.value = null
    tunrOffAddCautionSidebar()
  }
  const addCaution = () => {
    isSavingAddCaution.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/cautionInf`, {
        info_table: cautionInfoTable.value,
        info_idx: cautionInfoIdx.value,
        member_idx: influencerData.value.idx,
        reason: cautionWrittenReason.value ?? cautionSelectedReason.value,
      })
      .then(() => {
        makeToast('primary', '경고추가에 성공했습니다')
        // store.dispatch(`${APP_STORE_MODULE_NAME}/fetchCautionCount`,
        //   {
        //     inf_idx: influencerData.value.idx,
        //   })
        //   .then(response => {
        //     cautionCount.value = response.data.data.caution_count
        //     if (cautionCount.value >= 3) {
        //       turnOnBlackInfluencerModal()
        //     }
        //   })
        resetAddCaution()
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '경고추가에 실패했습니다')
      })
      .finally(() => {
        isSavingAddCaution.value = false
      })
  }

  const claims = ref()
  const claimTableFields = [
    'idx', 'campaign_idx', 'title', 'step', 'reason',
    'created_at',
  ]
  const visibleClaimListSidebar = ref(false)
  const turnOnClaimListSidebar = () => {
    claims.value = []
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchClaimList`, {
      inf_idx: influencerData.value.idx,
    }).then(response => {
      claims.value = response.data.data
    }).catch(() => {
      makeToast('danger', '중재 목록을 불러오는데 실패했습니다')
    })
    visibleClaimListSidebar.value = true
  }
  const turnOffClaimListSidebar = () => {
    visibleClaimListSidebar.value = false
  }

  const visibleUpdateScoreSidebar = ref(false)
  const timeScore = ref()
  const guideScore = ref()
  const contactScore = ref()
  const getLevel = score => {
    if (score <= 4) {
      return 1
    }
    if (score <= 8) {
      return 2
    }
    if (score <= 12) {
      return 3
    }
    if (score <= 16) {
      return 4
    }
    return 5
  }
  const timeLevel = computed(() => getLevel(timeScore.value))
  const guideLevel = computed(() => getLevel(guideScore.value))
  const contactLevel = computed(() => getLevel(contactScore.value))

  const totalScore = computed(() => timeScore.value + guideScore.value + contactScore.value)
  const totalLevel = computed(() => {
    if (totalScore.value <= 12) {
      return 1
    }
    if (totalScore.value <= 24) {
      return 2
    }
    if (totalScore.value <= 36) {
      return 3
    }
    if (totalScore.value <= 48) {
      return 4
    }
    return 5
  })

  const commentForUpdateScore = ref()
  const inputTimeScore = value => {
    timeScore.value = parseFloat(value) || null
  }
  const inputGuideScore = value => {
    guideScore.value = parseFloat(value) || null
  }
  const inputContactScore = value => {
    contactScore.value = parseFloat(value) || null
  }
  const inputCommentForUpdateScore = value => {
    commentForUpdateScore.value = value
  }
  const isSavingUpdateScore = ref(false)
  const turnOnUpdateScoreSidebar = async () => {
    await fetchScoreEvaluates()
    timeScore.value = scoreEvaluates.value.find(e => e.type === 'TIME').score
    guideScore.value = scoreEvaluates.value.find(e => e.type === 'GUIDE').score
    contactScore.value = scoreEvaluates.value.find(e => e.type === 'CONTACT').score
    commentForUpdateScore.value = null
    visibleUpdateScoreSidebar.value = true
  }
  const turnOffUpdateScoreSidebar = () => {
    visibleUpdateScoreSidebar.value = false
  }
  const updateScore = () => {
    isSavingUpdateScore.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/updateScore`, {
      inf_idx: influencerData.value.idx,
      time_score: timeScore.value,
      guide_score: guideScore.value,
      contact_score: contactScore.value,
      comment: commentForUpdateScore.value,
    }).then(() => {
      makeToast('primary', '평점을 수정하는데 성공했습니다')
      fetchDetail()
      fetchTimelines()
      turnOffUpdateScoreSidebar()
    }).catch(() => {
      makeToast('danger', '평점을 수정하는데 실패했습니다')
    }).finally(() => {
      isSavingUpdateScore.value = false
    })
  }

  return {
    claims,
    claimTableFields,
    visibleClaimListSidebar,
    turnOnClaimListSidebar,
    turnOffClaimListSidebar,

    makeToast,

    // InfluencerInfoCard
    infIdx,
    influencerData,
    mystoreData,
    campaignData,
    timelineData,
    snsData,
    snsAuthData,
    cautionCount,
    chatCount,
    claimCount,
    deliveryDestinations,
    deliveryDestinationCount,
    ssnData,
    hasLoadError,
    fetchDetail,
    fetchTimelines,
    genderOptions,
    genderSelected,
    restrictedDate,
    setGender,
    setRestrictedDate,

    // MystoreInfoCard
    mainProps,
    mystoreLink,
    makeCopyToast,
    linkToAdminMystore,
    linkToMystore,

    // SnsList
    snsList,
    isVisibleOnlyActiveSns,

    // AddNewSnsSidebar
    visibleAddNewSnsSidebar,
    snsIdToBeAdded,
    serviceOfNewSns,
    serviceOptions,
    isSavingAddNewSns,
    isValidAddNewSns,
    turnOnAddNewSnsSidebar,
    turnOffAddNewSnsSidebar,
    resetAddNewSnsSidebar,
    addNewSns,
    setSnsIdToBeAdded,
    setServiceOfNewSns,

    // UpgradeToInstaBizSidebar
    visibleUpgradeToInstaBizSidebar,
    snsIdxOfUpgradeToInstaBiz,
    campaignTitleOfUpgradeToInstaBiz,
    isSavingUpgradeToInstaBiz,
    isValidUpgradeToInstaBiz,
    turnOnUpgradeToInstaBizSidebar,
    turnOffUpgradeToInstaBizSidebar,
    resetpgradeToInstaBizForm,
    upgradeToInstaBiz,
    setCampaignTitle,

    // ActionCard
    isSaving,
    saveButtonText,
    saveInfluencerData,

    // ManagePointSidebar
    visiblePointSidebar,
    pointState,
    pointAmount,
    pointReason,
    pointStateOptions,
    isSavingPoint,
    isValidPoint,
    tunrOnPointSidebar,
    tunrOffPointSidebar,
    resetPointSidebar,
    updatePoint,
    setPointState,
    setPointAmount,
    setPointReason,

    // AddCautionSidebar
    visibleAddCautionSidebar,
    cautionInfoTable,
    cautionInfoIdx,
    cautionSelectedReason,
    cautionWrittenReason,
    tunrOnAddCautionSidebar,
    tunrOffAddCautionSidebar,
    infoTableOptions,
    campaignReasonOptions,
    marketReasonOptions,
    infoTableSelected,
    campaignReasonSelected,
    marketReasonSelected,
    isValidAddCaution,
    isSavingAddCaution,
    isCampaignStep,
    isMarketStep,
    isDirectlyWriteReason,
    resetAddCaution,
    addCaution,
    setCautionInfoTable,
    setCautionInfoIdx,
    setCampaignReason,
    setMarketReason,
    setWrittenReason,

    // Memo
    visibleMemoSidebar,
    memoContent,
    isValidMemo,
    isSavingMemo,
    turnOnMemoSidebar,
    turnOffMemoSidebar,
    resetMemo,
    saveMemo,

    // Revert
    revertInfluencer,

    // Delete
    visibleDeleteInfluencerSidebar,
    turnOnDeleteInfluencerSidebar,
    turnOffDeleteInfluencerSidebar,
    deleteReasonType,
    deleteReason,
    deleteReasonTypeOptions,
    isEtc,
    isValidDelete,
    isSavingDelete,
    resetDeleteSidebar,
    deleteInfluencer,
    setDeleteReasonType,
    setDeleteReason,

    // CampaignInfoCard
    campaignItems,
    campaignColumns,
    in30daysCampaign,
    isSelectedCampaign,
    isCanceledCampaign,
    isAppliedCampaign,
    isComptCampaign,
    isExpgCampaign,
    isProgressingCampaign,
    campaignCurrentPage,
    campaignPerPage,
    campaignTotalRows,
    campaignDataMeta,
    setIn30daysCampaignIn30days,
    setIsSelectedCampaign,
    setIsComptCampaign,
    setIsExpgCampaign,
    setIsProgressingCampaign,
    setCampaignCurrentPage,
    setIsCanceledCampaign,
    setIsAppliedCampaign,

    // ManageCautionSidebar
    visibleManageCautionSidebar,
    turnOnManageCautionSidebar,
    turnOffManageCautionSidebar,
    itemsOfManageCaution,
    columnsOfManageCaution,
    cautionStateOptions,
    fetchCautionItems,
    updateCautionState,
    idxManagedCaution,
    isBlackManagedCaution,
    restrictedDateManagedCaution,
    visibleAddCautionModal,
    visibleCancelCautionModal,
    turnOffAddCautionModal,
    turnOnCautionModal,
    updateCautionStateToAddition,
    onInputRestrictedDateManagedCaution,
    onInputIsBlackManagedCautioin,
    isReleaseBlackManageCaution,
    onInputIsReleaseBlackManagedCautioin,
    updateCautionStateToCancel,
    turnOffCancelCautionModal,

    // delivery destination sidebar
    visibleDeliveryDestinationSidebar,
    showDeliveryDestinationSidebar,
    closeDeliveryDestinationSidebar,
    addDeliveryDestination,

    columnsOfDeliveryDestination,

    // BlackInfluencerSidebar
    visibleBlackInfluencerSidebar,
    turnOnBlackInfluencerSidebar,
    turnOffBlackInfluencerSidebar,
    blackInfluencerReason,
    setBlackInfluencerReason,
    isSavingBlackInfluencer,
    isValidBlackInfluencer,
    blackInfluencerInSidebar,

    // ReleaseBlackInfluencerSidebar
    visibleReleaseBlackInfluencerSidebar,
    turenOnReleaseBlackInfluencerSidebar,
    turnOffReleaseBlackInfluencerSidebar,
    releaseBlackInfluencerRestictedDate,
    releaseBlackInfluencerReason,
    setReleaseBlackInfluencerRestrictedDate,
    setReleaseBlackInfluencerReason,
    isSavingReleaseBlackInfluencer,
    isValidReleaseBlackInfluencer,
    releaseBlackInfluencerInSidebar,

    // BlackInfluencerModal
    visibleBlackInfluencerModal,
    turnOnBlackInfluencerModal,
    turnOffBlackInfluencerModal,
    blackInfluencerInModal,

    visibleScoreSidebar,
    turnOnScoreSidebar,
    turnOffScoreSidebar,

    scoreEvaluates,
    scoreLogList,
    fetchScoreEvaluates,
    fetchScoreLogList,

    visibleUpdateScoreSidebar,
    timeScore,
    guideScore,
    contactScore,
    isSavingUpdateScore,
    commentForUpdateScore,
    turnOnUpdateScoreSidebar,
    turnOffUpdateScoreSidebar,
    inputTimeScore,
    inputGuideScore,
    inputContactScore,
    inputCommentForUpdateScore,
    updateScore,
    timeLevel,
    guideLevel,
    contactLevel,
    totalScore,
    totalLevel,

    linkGroupData,
  }
}

<template>
  <b-card title="캠페인 정보">
    <b-row class="mb-1 d-flex">
      <b-col class="d-flex">
        <b-form-group
          label="캠페인 필터링"
          class="mr-1"
        >
          <div class="d-flex">
            <b-form-checkbox
              :checked="isCompt"
              class="mr-1"
              @input="$emit('input-is-compt', $event)"
            >
              공모형
            </b-form-checkbox>
            <b-form-checkbox
              :checked="isExpg"
              class="mr-1"
              @input="$emit('input-is-expg', $event)"
            >
              체험단
            </b-form-checkbox>
            <b-form-checkbox
              :checked="isProgressing"
              class="mr-1"
              @input="$emit('input-is-progressing', $event)"
            >
              진행중
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-form-group label="지원 필터링">
          <div class="d-flex">
            <b-form-checkbox
              :checked="in30days"
              class="mr-1"
              @input="$emit('input-in30days', $event)"
            >
              30일 이내
            </b-form-checkbox>
            <b-form-checkbox
              :checked="isApplied"
              class="mr-1"
              @change="$emit('input-is-applied', $event)"
            >
              지원
            </b-form-checkbox>
            <b-form-checkbox
              :checked="isSelected"
              class="mr-1"
              @change="$emit('input-is-selected', $event)"
            >
              선정
            </b-form-checkbox>

            <b-form-checkbox
              :checked="isCanceled"
              class="mr-1"
              @input="$emit('input-is-canceled', $event)"
            >
              취소
            </b-form-checkbox>
          </div>
        </b-form-group>
      </b-col>
      <!-- <b-col>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="items"
          :fields="columns"
          primary-key="idx"
          show-empty
          responsive
          small
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(idx)="data">
            <b-link :to="{name: 'campaign-compt-active-detail', params: {idx: data.value}}">
              {{ data.value }}
            </b-link>
          </template>

          <template #cell(apply_state)="data">
            {{ data.item.apply.state }}
          </template>

          <template #cell(apply_created_at)="data">
            <div style="width: 90px">
              {{ resolveDatetime(data.item.apply.created_at) }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="6"
        class="d-flex align-items-center justify-content-star pl-2"
      >
        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
      </b-col>
      <b-col
        cols="6"
        class="d-flex align-items-center justify-content-end"
      >
        <b-pagination
          :value="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          first-number
          last-number
          @input="$emit('input-current-page', $event)"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BPagination,
  BLink,
  BFormGroup,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BPagination,
    BLink,
    BFormGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    in30days: {
      type: Boolean,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isCanceled: {
      type: Boolean,
      required: true,
    },
    isApplied: {
      type: Boolean,
      required: true,
    },
    isCompt: {
      type: Boolean,
      required: true,
    },
    isExpg: {
      type: Boolean,
      required: true,
    },
    isProgressing: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    dataMeta: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'input-in30days',
    'input-is-selected',
    'input-is-progressing',
    'input-is-compt',
    'input-is-expg',
    'input-current-page',
  ],
  setup() {
    const resolveDatetime = datetime => moment(datetime).format('YYYY-MM-DD HH:mm:ss')
    return {
      resolveDatetime,
    }
  },
}
</script>

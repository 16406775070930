<template>
  <div>
    <b-row>
      <b-col
        v-for="sns in snsData"
        :key="sns.idx"
        cols="12"
        xl="4"
        lg="4"
        md="4"
      >
        <sns-profile-card
          :sns-profile="sns"
          @turn-on-upgrade-to-insta-biz="$emit('turn-on-upgrade-to-insta-biz', $event)"
        />
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-primary"
          block
          @click="$emit('turn-on-add-new-sns')"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">SNS 추가</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SnsProfileCard from './SnsProfileCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    SnsProfileCard,
  },
  directives: {
    Ripple,
  },
  props: {
    snsData: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'turn-on-upgrade-to-insta-biz',
    'turn-on-add-new-sns',
  ],
}
</script>

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 인플루언서 디테일 받기
    fetchInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/detail/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 경고 개수
    fetchCautionCount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/caution-count/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 업데이트
    updateInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 포인트 관리
    pointInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/point/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 경고 관리
    cautionInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/caution/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 메모 작성
    memoInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/memo/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchTimelines(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/timelines/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getInfCautions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/cautions/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 회원탈퇴
    deleteInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/delete/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 재가입
    revertInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/revert/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 블랙
    banInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/ban/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 블랙해제
    releaseBanInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/release-ban/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 생성
    createInf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/create/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 sns추가
    addSns(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/add-sns/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 SNS 웹인증 티켓 발급
    makeSnsAuthTicket(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/sns/web-auth/ticket/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    infUpdateCautionState(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-caution-state/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCautionStateToAddition(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-caution-state-to-addition/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCautionStateToCancel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-caution-state-to-cancel/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    infUpdateCautionExpiredAt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-caution-expired_at/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScoreEvaluates(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/score-evaluates/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScoreLogList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/score-log-list/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClaimList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/claim-list/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateScore(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-score/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

<template>
  <tagby-sidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      포인트 관리
    </template>
    <template #content>
      <div class="demo-inline-spacing mb-1">
        <b-form-radio-group
          :value="pointState"
          :options="pointStateOptions"
          value-field="value"
          text-field="text"
          @input="$emit('input-point-state', $event)"
        />
      </div>
      <b-form-group label="금액">
        <b-form-input
          :value="pointAmount"
          type="number"
          @input="$emit('input-point-amount', $event)"
        />
      </b-form-group>
      <b-form-group label="사유">
        <b-form-textarea
          :value="pointReason"
          rows="8"
          @input="$emit('input-point-reason', $event)"
        />
      </b-form-group>
      <b-row class="d-flex mt-1 p-1">
        <b-button
          class="mr-1"
          variant="outline-primary"
          :disabled="!isValid"
          @click="$emit('update-point')"
        >
          <b-spinner
            v-if="isSaving"
            small
          />
          확인
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="$emit('hidden')"
        >
          취소
        </b-button>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BButton,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BRow,
    BButton,
    BSpinner,
    BFormTextarea,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    pointState: {
      type: String,
      default: '',
    },
    pointAmount: {
      type: String,
      default: '',
    },
    pointReason: {
      type: String,
      default: '',
    },
    pointStateOptions: {
      type: Array,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'hidden',
    'update-point',
    'input-point-state',
    'input-point-amount',
    'input-point-reason',
  ],
}
</script>

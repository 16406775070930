<template>
  <TagbySidebar
    title="중재 목록"
    :visible="visible"
    width="40%"
    @hidden="$emit('hidden')"
  >
    <template #content>
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        responsive
        small
      >
        <template #cell(campaign_idx)="data">
          <BLink
            :to="{ name: 'campaign-compt-active-detail', params: { idx: data.value } }"
          >
            {{ data.value }}
          </BLink>
        </template>

        <template #cell(title)="data">
          {{ data.item.campaign.title }}
        </template>

        <template #cell(created_at)="data">
          <TagbyDatetimeColumn :value="data.value" />
        </template>
      </BTable>
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BLink,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    TagbySidebar,
    BTable,
    BLink,
    TagbyDatetimeColumn,
  },
  props: {
    visible: Boolean,
    items: Array,
    fields: Array,
  },
  setup() {

  },
})
</script>

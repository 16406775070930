<template>
  <tagby-sidebar
    :visible="visible"
    width="30%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      경고 추가
    </template>
    <template #content>
      <div
        class="mb-1"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="문제대상"
            >
              <v-select
                :value="infoTableSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="infoTableOptions"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
                @input="$emit('input-info-table', $event)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCampaignStep">
          <b-col>
            <b-form-group
              label="캠페인 인덱스"
            >
              <b-form-input
                :value="infoIdx"
                trim
                @input="$emit('input-info-idx', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCampaignStep">
          <b-col>
            <b-form-group
              label="제한사유"
            >
              <v-select
                :value="campaignReasonSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="campaignReasonOptions"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
                @input="$emit('input-campaign-reason', $event)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isMarketStep">
          <b-col>
            <b-form-group
              label="상품 인덱스"
            >
              <b-form-input
                :value="cautionInfoIdx"
                trim
                @input="$emit('input-info-idx', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isMarketStep">
          <b-col>
            <b-form-group
              label="제한사유"
            >
              <v-select
                :value="marketReasonSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="marketReasonOptions"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
                @input="$emit('input-market-reason', $event)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isDirectlyWrittenReason">
          <b-col>
            <b-form-textarea
              :value="cautionWrittenReason"
              rows="8"
              @input="$emit('input-written-reason', $event)"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid"
              @click="$emit('add-caution')"
            >
              <b-spinner
                v-if="isSaving"
                small
              />
              확인
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormTextarea,
    vSelect,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    infoTableSelected: {
      type: Object,
      default: null,
    },
    infoIdx: {
      type: String,
      default: '',
    },
    campaignReasonSelected: {
      type: [Object, String],
      default: null,
    },
    marketReasonSelected: {
      type: [Object, String],
      default: null,
    },
    cautionWrittenReason: {
      type: String,
      default: '',
    },
    infoTableOptions: {
      type: Array,
      required: true,
    },
    campaignReasonOptions: {
      type: Array,
      required: true,
    },
    marketReasonOptions: {
      type: Array,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    isCampaignStep: {
      type: Boolean,
      required: true,
    },
    isMarketStep: {
      type: Boolean,
      required: true,
    },
    isDirectlyWrittenReason: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'hidden',
    'add-caution',
    'input-info-table',
    'input-info-idx',
    'input-campaign-reason',
    'input-market-reason',
    'input-written-reason',
  ],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
